/* You can add global styles to this file, and also import other style files */
@import "../../../../submodules/ui/packages/ui-base/src/scss/mv/_settings.colors";
@import "../../../../submodules/ui/packages/ui-base/src/scss/pv/_settings.colors";
@import "./mixin.scss";

$breakpoint-tablet: 768px;

$primary: map-get($colors, "raspberry");
$secondary: map-get($colors, "green");
$tertiary: map-get($colors, "silver");
$black: map-get($colors, "black");
$white: map-get($colors, "white");
$text-color: map-get($colors, "grey--medium");
$color-error: map-get($colors, "error");
$dark-blue: map-get($colors, "dark-blue");

// parameter pv //
$pv-primary: map-get($pv-colors, "gray-500");
$pv-secondary: map-get($pv-colors, "subtitle");
$pv-text-color: map-get($pv-colors, "text--light");
$pv-color-error: map-get($pv-colors, "error");

body {
    h1 {
        font-size: 3rem;
    }

    h2 {
        font-size: 2.5rem;
    }

    h3 {
        font-size: 2rem;
    }

    h4 {
        font-size: 1.5rem;
    }

    p {
        font-size: 1rem;
    }

    .logo {
        display: inline-block;
        text-align: right;
        margin: -2rem;

        p {
            padding-right: 2rem;
            margin-top: -2rem;
        }
    }

    .error {
        height: 30px;
        color: $color-error;
    }

    .size-25 {
        font-size: 25em;
    }

    .size-1 {
        font-size: 1.5em;
    }

    .size-2 {
        font-size: 2em;
    }

    .size-3 {
        font-size: 3em;
    }

    .size-4 {
        font-size: 4em;
    }

    .color-primary {
        color: $primary;
    }

    .color-secondary {
        color: $secondary;
    }

    .color-tertiary {
        color: $tertiary;
    }

    .color-black {
        color: $black;
    }

    .color-white {
        color: $white;
    }

    .color-text {
        color: $text-color;
    }

    .border-bottom-primary {
        border-bottom: 1px solid $primary;
    }

    .background-color-primary {
        background-color: $primary;
    }

    .mousse-hover {
        cursor: pointer;
        color: $primary;

        &:hover {
            color: $tertiary;
        }
    }

    a,
    .link-style1 {
        text-decoration: underline;
        color: $primary;
        cursor: pointer;

        &:hover {
            color: $primary;
        }

        &.link-style2 {
            text-decoration: none;
            color: $black;

            &:hover {
                color: $primary;
            }
        }
    }

    input,
    select,
    textarea {
        padding: 0.5em;
        width: 100%;
        color: $black;
        background-color: $white;

        &:focus {
            border: 1px solid $primary;
            outline: none;
        }

        border: 1px solid $tertiary;

        &:hover {
            border: 1px solid $primary;
        }
    }

    label {
        margin: 0;
    }

    .label-help {
        font-style: italic;
    }

    .input-radio {
        display: flex;
        font-size: 1.2em;

        input {
            display: inline-block;
            width: auto;
        }

        label {
            margin-left: 1em;
        }
    }

    .separator {
        display: flex;
        align-items: center;
        color: $primary;

        &::after,
        &::before {
            content: "";
            flex-grow: 1;
            border-top: 1px solid $primary;
        }

        &::after {
            margin-left: 1em;
        }

        &::before {
            margin-right: 1em;
        }
    }

    .input {
        &__flex {
            display: flex;

            :first-child {
                padding-right: 2rem;
            }
        }
    }

    .input-connection {
        border-bottom: 2px solid $text-color;

        &:hover {
            border-bottom: 2px solid $primary;

            & span,
            & label {
                color: $primary;
            }
        }

        & .input__wrapper {
            display: flex;
            align-items: center;

            & span {
                font-size: 2rem;
            }

            & input {
                flex: 1;
                border: 0;
                color: $text-color;

                &:focus {
                    outline: none;
                }
            }
        }
    }

    .title-style1 {
        @include title-mv;

        &--dark-blue {
            @include title-mv;
            background-color: $dark-blue;

            &::after {
                background-color: $dark-blue;
            }
        }
    }

    .title-style2 {
        color: $primary;
    }

    .title-style3 {
        color: map-get($colors, "black");

        i {
            margin-right: 0.3em;
        }
    }

    .share-icon {
        height: 70px;
        width: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 50%;
        box-shadow: 0 10px 20px 0 $tertiary;

        .qrcode,
        .mv-icons--design--link {
            font-size: 2em;
        }

        a {
            font-size: 3em;
            text-decoration: none;
            color: $primary;
        }
    }

    .input-switch {
        position: relative;
        width: 54px;
        height: 34px;
        margin-right: 5px;

        input {
            opacity: 0;
            width: 0;
            height: 0;

            &:checked {
                & + .slider {
                    background-color: $primary;

                    &::before {
                        transform: translateX(26px);
                    }
                }
            }
        }

        .slider {
            position: absolute;
            cursor: pointer;
            width: 54px;
            height: 26px;
            top: 0;
            right: 0;
            background-color: $tertiary;
            transition: 0.4s;

            &::before {
                position: absolute;
                content: "";
                height: 20px;
                width: 20px;
                left: 4px;
                bottom: 3px;
                background-color: $white;
                -webkit-transition: 0.4s;
                transition: 0.4s;
            }
        }
    }

    .image-box {
        position: relative;
        cursor: pointer;

        width: 230px;
        height: 150px;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        div {
            border-radius: 50%;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 3em;
            background-color: $white;
            width: 1.2em;
            height: 1.2em;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .select-style1 {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        border: 1px solid $primary;

        select {
            border: none;
        }

        i {
            font-size: 3em;
            background-color: $primary;
            position: relative;
            color: $white;

            &::after {
                position: absolute;
                content: "";
                width: 0.6em;
                height: 100%;
                background-color: $primary;
                clip-path: polygon(100% 0, 0% 100%, 100% 100%);
                left: -60%;
                border: 5px solid $primary;
            }
        }
    }

    .download-box {
        height: 150px;
        width: 230px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 3px dashed $tertiary;

        div {
            margin: 0;
        }

        img {
            height: 30%;
            width: 30%;
            font-size: 3em;
        }

        input {
            display: none;
        }
    }

    .logo-img {
        display: flex;
        align-items: center;

        img {
            height: 150px;
            width: 150px;
        }
    }

    .avatar {
        display: inline-block;
        height: 135px;
        width: 135px;
        padding: 2em;
        position: relative;

        &:hover {
            .avatar__edith {
                display: flex;
            }
        }

        &__profile-img {
            z-index: 3;
        }

        input {
            display: none;
        }

        img {
            position: absolute;
            height: 100%;
            width: 100%;
            border-radius: 50%;
            object-fit: cover;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        i {
            position: absolute;
            font-size: 10em;
            left: 50%;
            z-index: 0;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &__edith {
            display: none;
            background-color: rgba(5, 5, 5, 0.219);
            height: 110%;
            width: 110%;
            z-index: 4;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;

            justify-content: center;
            align-items: flex-end;
            color: $white;
            padding-bottom: 1em;
            cursor: pointer;

            p:first-child {
                margin-right: 1em;
            }
        }
    }

    .selected-box-img {
        height: 100%;
        width: 100%;

        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 7px solid $primary;

        i {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            font-size: 2em;
            border-radius: 50%;
            height: 1.3em;
            width: 1.3em;
            background-color: $primary;
        }
    }

    .dot-scroll {
        ul {
            display: flex;
            flex-direction: column;
            list-style: none;
            justify-content: space-between;

            li {
                background-color: $tertiary;
                height: 1em;
                width: 1em;
                border-radius: 50%;

                cursor: pointer;

                &:hover,
                &.selected {
                    background-color: $primary;
                    transition: height 0.1s;
                }
            }
        }
    }

    .input-addemail {
        display: flex;

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 1em;
            font-size: 1.5em;
            border: 1px solid $tertiary;
            border-left: 0;
            color: $tertiary;

            &:hover {
                border: 1px solid $primary;
                color: $primary;
            }
        }
    }

    .little-avatar {
        background-color: $tertiary;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        text-transform: uppercase;
        font-size: 1em;
    }

    table {
        margin-top: 1em;
        table-layout: fixed;
        width: 100%;
        border-collapse: collapse;

        tr {
            display: flex;
        }

        thead {
            color: $primary;
            font-size: 1.5em;

            th,
            td {
                display: flex;

                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7) {
                    flex: 1;
                    justify-content: center;
                }
            }
        }

        tbody {
            tr {
                display: flex;
                align-items: center;
                margin-bottom: 1em;

                &:hover {
                    color: $tertiary;
                    cursor: pointer;

                    .little-avatar {
                        background-color: $tertiary;
                    }
                }

                &.selected {
                    td {
                        color: $primary;

                        .little-avatar {
                            background-color: $primary;
                        }
                    }
                }
            }
        }

        th,
        td {
            &:nth-child(1) {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                padding-right: 1em;
                flex: 3;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            &:nth-child(6),
            &:nth-child(5),
            &:nth-child(7) {
                flex: 1;
                text-align: center;
            }
        }
    }

    &.pv {
        .color-primary {
            color: $pv-primary;
        }

        .color-secondary {
            color: $pv-secondary;
        }

        .color-black {
            color: $black;
        }

        .color-text {
            color: $pv-text-color;
        }

        .color-error {
            color: $pv-color-error;
        }

        .error {
            color: $pv-color-error;
        }

        .background-color-primary {
            background-color: $pv-secondary;
        }

        .border-bottom-primary {
            border-bottom: 1px solid $pv-secondary;
        }

        .mousse-hover {
            color: $pv-primary;
        }

        a,
        .link-style1 {
            color: $pv-secondary;

            &:hover {
                color: $pv-secondary;
            }

            &.link-style2 {
                text-decoration: none;
                color: map-get($pv-colors, "black");

                &:hover {
                    color: $pv-secondary;
                }
            }
        }

        input,
        select,
        textarea {
            &:focus {
                border: 1px solid $pv-secondary;
            }

            &:hover {
                border: 1px solid $pv-secondary;
            }
        }

        .select-style1 {
            border: 1px solid $pv-primary;

            select {
                border: none;
            }

            i {
                background-color: $pv-secondary;

                &::after {
                    background-color: $pv-secondary;
                    border: 5px solid $pv-secondary;
                }
            }
        }

        .separator {
            color: $pv-secondary;
            width: 100%;

            &::after,
            &::before {
                border-top: 1px solid $pv-secondary;
            }
        }

        .input-connection {
            border-bottom: 3px solid $text-color;

            &:hover {
                border-bottom: 3px solid $pv-secondary;

                & span,
                & label {
                    color: $pv-secondary;
                }
            }

            & .input__wrapper {
                & input {
                    border: 0;

                    &:focus {
                        outline: none;
                    }
                }
            }
        }

        .title-style1 {
            background-color: transparent;
            color: $text-color;
            padding: 0;

            &::after {
                width: 120px;
                height: 10px;
                background-color: $pv-secondary;
                clip-path: polygon(0% 50%, 0% 0%, 100% 0%, 100% 50%);
                top: 100%;
                left: 0;
                border: 0;
            }
        }

        .title-style2 {
            color: $text-color;
            position: relative;

            &::after {
                content: "";
                width: 120px;
                height: 5px;
                background-color: $pv-secondary;
                clip-path: polygon(0% 50%, 0% 0%, 100% 0%, 100% 50%);
                position: absolute;
                top: 100%;
                left: 0;
            }
        }

        .share-icon {
            border: 1px solid $tertiary;
            border-radius: 0px;
            box-shadow: 0 0 0 0;
        }

        .input-switch {
            input {
                &:checked {
                    & + .slider {
                        background-color: $pv-secondary;
                    }
                }
            }
        }

        .image-box {
            border: 1px solid $tertiary;

            div {
                border-radius: 0%;
                border: 1px solid $tertiary;
                color: $tertiary;
            }
        }

        .download-box {
            border: 1px dashed $tertiary;
        }

        .selected-box-img {
            border: 1px solid $pv-primary;

            i {
                border-radius: 0;
                background-color: $pv-secondary;
            }
        }

        .dot-scroll {
            ul {
                li {
                    border-radius: 0;

                    &:hover,
                    &.selected {
                        background-color: $pv-secondary;
                    }
                }
            }
        }

        .input-addemail {
            div {
                border: 1px solid $tertiary;
                color: $tertiary;

                &:hover {
                    border: 1px solid $pv-secondary;
                    color: $pv-secondary;
                }
            }
        }

        .little-avatar {
            border-radius: 0;
            border: 1px solid $tertiary;
            background-color: $white;
            color: $pv-primary;
        }

        table {
            thead {
                color: $pv-secondary;
            }

            tbody {
                tr {
                    &:hover {
                        color: $pv-primary;

                        .little-avatar {
                            border: 1px solid $pv-primary;
                            background-color: $white;
                        }
                    }

                    &.selected {
                        td {
                            color: $pv-secondary;

                            .little-avatar {
                                background-color: $pv-secondary;
                                border: 1px solid $pv-secondary;
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* For mobile phones: */
@media only screen and (max-width: $breakpoint-tablet) {
    body {
        h1 {
            font-size: 2em;
        }

        h2 {
            font-size: 1.7em;
        }

        h3 {
            font-size: 1.5em;
        }

        .download-box {
            margin: 1em;
        }
    }
}
