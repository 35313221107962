@mixin title-mv {
    display: inline-block;
    position: relative;
    background-color: $primary;
    color: $white;
    padding: 0 0.5em;

    &::after {
        position: absolute;
        content: "";
        width: 0.6em;
        height: 100%;
        background-color: $primary;
        clip-path: polygon(0 0, 100% 0, 0 100%);
        left: 100%;
    }
}
