$pv-colors: (
    actions: #206e6e,
    actions-secondary: #134444,
    background: #f8f9fa,
    header-background: #f8f9fa,
    subtitle: #009f8b,
    text--light: #212529,
    text: #000,
    title: #3b4c68,
    info: #279989,
    error: #be0342,
    warning: #fc533d,
    'white': #fff,
    'black': #000,
    'gray-500': #a1a0a0,
);

@each $name, $value in $pv-colors {
    .pv-colors--#{$name} {
        color: $value;
    }
}
